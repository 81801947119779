body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background: #212121;
  color: #f6f6f6;
}

* {
  box-sizing: border-box;
}
